import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingScreen from "./LoadingScreen";
import { CircularProgressbar } from "react-circular-progressbar"; // Import the circular progress bar component
import "react-circular-progressbar/dist/styles.css";

const SEOAITool = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState("https://www.canva.com/");
    const [seoScore, setSeoScore] = useState(0); // SEO Score state

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(
                    `http://localhost:5000/analyze-onpage-seo?url=${url}`
                );

                if (response.status !== 200) {
                    throw new Error(`Unexpected response status: ${response.status}`);
                }

                setData(response.data);
                console.log(response.data);
                // calculateSeoScore(response.data.analysis);
            } catch (err) {
                console.error("Error fetching data:", err.message);

                if (err.response) {
                    setError(`Server error: ${err.response.data.error || "Unknown error occurred"}`);
                } else if (err.request) {
                    setError("Network error: Failed to receive a response from the server.");
                } else {
                    setError("Unexpected error occurred. Please try again.");
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [url]);

    // // Calculate the SEO score
    // const calculateSeoScore = (analysis) => {
    //     const { performance, htmlValidation, sslDetails } = analysis;

    //     // Normalize the performance score (considering a 100-point scale for simplicity)
    //     const performanceScore = performance.performanceScore;

    //     // Adjust the HTML Score: 100 if no errors, decreasing by 5 points per error
    //     const htmlScore = htmlValidation.messages.length === 0 ? 100 : 100 - (htmlValidation.messages.length * 5);

    //     // SSL Score: 100 if SSL is present, else 0
    //     const sslScore = sslDetails ? 100 : 0;

    //     // Calculate final SEO score with weights
    //     const finalSeoScore =
    //         (performanceScore * 0.5) +
    //         (htmlScore * 0.3) +
    //         (sslScore * 0.2);

    //     setSeoScore(Math.min(finalSeoScore, 100)); // Ensure score doesn't exceed 100
    // };

    // Rendering the data
    // const renderData = () => {
    //     if (!data) return null;

    //     const { analysis } = data;
    //     const { performance, htmlValidation, sslDetails } = analysis;

    //     return (
    //         <div className="space-y-8 mt-12">
    //             {/* Circular Progress Bar for SEO Score with Explanation */}

    //         </div>
    //     );
    // };

    return (
        <div className="p-8">
            {/* {loading && <LoadingScreen textToShow={"Getting SEO data..."} />}
            {error && <p className="text-red-500">{error}</p>}
            {!loading && !error && renderData()} */}
        </div>
    );
};

// Tailwind CSS utility for coloring performance score
const getColorStyle = (score) => {
    if (score >= 80) return "text-green-600 font-bold";
    if (score >= 50) return "text-yellow-500 font-bold";
    return "text-red-600 font-bold";
};

export default SEOAITool;
