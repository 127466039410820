import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import dayjs from 'dayjs';
import axios from 'axios'; // Ensure axios is installed for making API calls
import Cookies from 'js-cookie';
import LoadingScreen from './LoadingScreen';
import { getSecureCookie, setSecureCookie } from '../utilities/safeSaves';
import Footer from './Footer';
import MarxIPGOrder from './PaymentComponent';
import Modal from './Modal';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const UsagePage = () => {
    const [dataRange, setDataRange] = useState('30days');
    const [filteredData, setFilteredData] = useState([]);
    const [apiKeys, setApiKeys] = useState([]); // State for API keys
    const [balance, setBalanceCredits] = useState(0); // State for API keys
    const [plan, setPlan] = useState('basic'); // State for API keys
    const [messagesSent, setMessages_sent] = useState('basic'); // State for API keys

    const [showNameModal, setShowNameModal] = useState(false); // State to manage name modal visibility
    const [showKeyModal, setShowKeyModal] = useState(false); // State to manage key modal visibility
    const [generatedApiKey, setGeneratedApiKey] = useState(''); // State for the generated API key
    const [apiKeyName, setApiKeyName] = useState(''); // State for API key name
    const uid = getSecureCookie('uid');
    const token = getSecureCookie('accessToken');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // State for loading
    const [showConfirmModal, setShowConfirmModal] = useState(false); // State to manage confirmation modal visibility
    const [apiKeyToDelete, setApiKeyToDelete] = useState(null); // State to store the API key ID to delete
    const [orderDetails, setOrderDetails] = useState(null);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    const handleRecharge = (rechargeOption) => {
        setOrderDetails({
            price: rechargeOption.price,
            summary: `You are going to charge ${rechargeOption.price} dollars for the ${getSecureCookie('plan')} plan recharge with ${rechargeOption.credits} credits`,
            type: 'recharge'
        });
        sessionStorage.setItem('paymentAction', 'recharge')
        setIsPaymentModalOpen(true);  // Open the modal
    };

    useEffect(() => {
        if (orderDetails !== null)
            if (orderDetails.summary) {
                console.log(orderDetails.summary); // Logs the updated value after setOrderDetails is completed
            }
    }, [orderDetails]);

    // Sample bot usage data
    const [usageData, setUsageData] = useState([]);

    useEffect(() => {
        const fetchUsageData = async () => {
            try {
                const response = await axios.get('https://api-bny5h3g2lq-uc.a.run.app/get-all-chatbots-usage/', {
                    headers: {
                        Authorization: `Bearer ${token}`, // JWT token authorization header
                    },
                });

                // Process the response data to match the desired format
                const formattedData = response.data.flatMap((chatbot) =>
                    chatbot.usageData.map((usage) => ({
                        bot: chatbot.chatbotId,
                        date: usage.date,
                        messages: usage.messages,
                    }))
                );

                // Set the formatted data into the state
                setUsageData(formattedData);
            } catch (error) {
                console.error("Error fetching usage data:", error);
            }
        };

        fetchUsageData();
    }, [token]);

    // Log the usage data whenever it changes
    useEffect(() => {
        console.log(usageData);
    }, [usageData]); // This will log after `usageData` is updated



    // Prepare chart data dynamically
    const chartData = {
        labels: [...new Set(usageData.map((data) => data.date))], // Unique months
        datasets: [...new Set(usageData.map((data) => data.bot))].map((bot, index) => ({
            label: bot,
            data: usageData.filter((d) => d.bot === bot).map((d) => d.messages),
            backgroundColor: `rgba(${(index + 1) * 50}, 99, 132, 0.6)`,
            borderWidth: 1,
            borderColor: `rgba(${(index + 1) * 50}, 99, 132, 1)`,
            fill: false,
            tension: 0.4,
        })),
    };

    // Filter data based on the selected date range
    const filterData = (range) => {
        const today = dayjs();
        let filtered;
        switch (range) {
            case '10days':
                filtered = usageData.filter((data) => dayjs(data.date).isAfter(today.subtract(10, 'day')));
                break;
            case '30days':
                filtered = usageData.filter((data) => dayjs(data.date).isAfter(today.subtract(30, 'day')));
                break;
            case '3months':
                filtered = usageData.filter((data) => dayjs(data.date).isAfter(today.subtract(3, 'month')));
                break;
            default:
                filtered = usageData;
        }
        setFilteredData(filtered);
    };

    useEffect(() => {
        filterData(dataRange);
    }, [dataRange]);

    useEffect(() => {
        fetchApiKeys()
    }, [])

    const fetchApiKeys = async () => {
        setLoading(true);
        setError('');
        if (token) {
            try {
                const response = await axios.get('https://api-bny5h3g2lq-uc.a.run.app/api/v1/api-keys', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log("API Response:", response.data); // Log full API response
                setApiKeys(response.data.apiKeys); // Ensure this contains the expected structure
                setBalanceCredits(response.data.balance)
                setMessages_sent(response.data.messages_sent)
                setPlan(response.data.plan)
                setLoading(false);

                console.log(response.data.plan)
                setSecureCookie('username', response.data.name, { expires: 7, secure: true });
                setSecureCookie('plan', response.data.plan, { expires: 7, secure: true });

            } catch (error) {
                let errorMessage = 'Failed to fetch API keys.';
                if (error.response) {
                    errorMessage = error.response.data.error || errorMessage;
                }
                setError(errorMessage);

            } finally {
            }
        }
        else {
            setLoading(false);
        }
    };


    // Render error or loading state
    if (loading) return <LoadingScreen textToShow={"Give us a moment..."} />;
    if (error) return <div className="text-red-500">{error}</div>;

    // Generate a new API key for the current user
    const generateApiKey = async () => {
        if (!apiKeyName) {
            alert('Please enter a name for the API key.'); // Validate API key name
            return;
        }

        try {
            const response = await axios.post('https://api-bny5h3g2lq-uc.a.run.app/api/v1/generate-api-key', {
                name: apiKeyName // Use custom name from input
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.message === "API key generated successfully.") {
                setGeneratedApiKey(response.data.apiKey);
                setShowKeyModal(true); // Show key modal
                setShowNameModal(false); // Close name modal
                fetchApiKeys(); // Refresh API keys after generating a new one
            }
        } catch (error) {
            console.error('Error generating API key:', error);
        }
    };

    // Function to delete an API key
    const deleteApiKey = async () => {
        setLoading(true)
        console.log(`https://api-bny5h3g2lq-uc.a.run.app/api/v1/revoke-api-key/${apiKeyToDelete}`)
        try {
            const response = await axios.delete(`https://api-bny5h3g2lq-uc.a.run.app/api/v1/revoke-api-key/${apiKeyToDelete}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.message === "API key revoked successfully.") {
                // Remove the deleted key from the state
                setApiKeys((prevKeys) => prevKeys.filter((key) => key.id !== apiKeyToDelete));
                setShowConfirmModal(false)
                setLoading(false)
                fetchApiKeys();
            }
        } catch (error) {
            console.error('Error deleting API key:', error);
        }
    };


    const planLimits = { basic: 150, pro: 600, super: 1400 };
    const monthlyLimit = planLimits[plan];
    const usagePercentage = (messagesSent / monthlyLimit) * 100;

    const creditOptions = {
        basic: [
            { price: 1, credits: 10 },
            { price: 7, credits: 150 },
            { price: 11, credits: 300 },
            { price: 21, credits: 680 },
        ],
        pro: [
            { price: 1, credits: 20 },
            { price: 7, credits: 200 },
            { price: 11, credits: 400 },
            { price: 21, credits: 850 },
        ],
        super: [
            { price: 1, credits: 30 },
            { price: 7, credits: 250 },
            { price: 11, credits: 500 },
            { price: 21, credits: 1050 },
        ],
    };

    // Function to copy API key to clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(generatedApiKey);
        alert('API key copied to clipboard! Remember to keep it secure.');
    };

    const getBackgroundColor = (percentage) => {
        if (percentage <= 25) {
            return 'linear-gradient(to right, #4c6ef5, #a2b3f5)'; // Indigo to Light Indigo
        } else if (percentage <= 50) {
            return 'linear-gradient(to right, #4c6ef5, #825ee4, #a2b3f5)'; // Indigo to Purple to Light Indigo
        } else if (percentage <= 75) {
            return 'linear-gradient(to right, #4c6ef5, #825ee4, #9c27b0, #a2b3f5)'; // Indigo to Purple to Dark Purple to Light Indigo
        } else {
            return 'linear-gradient(to right, #4c6ef5, #825ee4, #9c27b0, #e91e63, #a2b3f5)'; // Indigo to Purple to Dark Purple to Pink to Light Indigo
        }
    };



    return (
        <>
            <div className="container mx-auto p-10">
                <h1 className="mt-6 text-3xl sm:text-4xl text-center font-bold text-white mb-6 uppercase" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)' }}>Usage & Balance</h1>

                <div className="flex justify-end mt-4">
                    <button
                        className="mb-2 p-2 bg-green-600 text-white rounded-lg shadow hover:bg-green-700 transition duration-300 transform hover:scale-105"
                        onClick={() => window.location.replace('/select-service')}
                    >
                        Create Your Bot
                    </button>
                </div>
                {/* Flex Container for Balance Display and Recharge Section */}
                <div className="flex flex-col md:flex-row justify-center gap-6">
                    {/* Balance Display */}
                    <div className="mb-6 md:mb-0 p-6 rounded-lg shadow-lg bg-indigo-900/80 w-full md:w-4/6 transition-transform duration-300 hover:scale-103">
                        <h2 className="text-2xl mb-2 font-bold text-white" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                            Current Balance
                        </h2>
                        <p className="text-4xl sm:text-5xl font-bold text-white" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                            {balance} Credits
                        </p>

                        {/* Usage Bar with Dynamic Loading Color */}
                        <div className="relative w-full h-4 bg-gray-300/5 rounded-lg mt-4 border-2 border-gray-500">
                            <div
                                className="absolute h-full rounded-lg transition-all duration-500"
                                style={{
                                    width: `${usagePercentage}%`,
                                    background: getBackgroundColor(usagePercentage), // Dynamic color based on usage
                                }}
                            />
                        </div>

                        <p className="mt-2 text-sm text-gray-300">
                            {messagesSent} / {monthlyLimit} Messages Used
                        </p>
                    </div>

                    {/* Recharge Section */}
                    <div className="p-6 bg-gray-800/10 rounded-lg shadow-lg mb-6 md:w-2/6 transition-transform duration-300 hover:scale-102 relative overflow-hidden">
                        {/* Shining border using pseudo-elements */}
                        <div className="absolute inset-0 border-4 rounded-lg border-transparent">
                            <div className="h-full w-full bg-gradient-to-r from-indigo-500/20 to-blue-500/20 animate-border"></div>
                        </div>

                        <h2 className="text-2xl font-semibold mb-4 text-white relative z-10">Recharge Credits</h2>
                        <div className="grid grid-cols-1 gap-4 relative z-10">
                            {creditOptions[plan].map((option, index) => (

                                <button
                                    key={index}
                                    className={`py-3 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold rounded-md shadow-lg transition-all duration-300 ease-in-out ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:from-pink-600 hover:to-purple-600 transform hover:scale-105 w-full border-2 border-purple-400'}`}
                                    onClick={() => handleRecharge(option)}
                                >
                                    ${option.price} for <strong>{option.credits}</strong> Credits

                                </button>
                            ))}
                        </div>
                    </div>
                </div>


                {/* API Keys List */}
                <div className="mt-8 p-6 bg-gray-50 rounded-lg shadow mb-6 bg-gray-800/10">
                    <div>
                        <button
                            className="mb-2 p-2 bg-pink-600 text-white rounded-lg shadow hover:bg-pink-700 transition duration-300 transform hover:scale-105 float-end"
                            onClick={() => setShowNameModal(true)}
                        >
                            Generate a new API Key
                        </button>
                    </div>
                    <h2 className="text-2xl font-semibold mb-4 text-white">Your API Keys</h2>
                    <div className="overflow-x-auto"> {/* Make table horizontally scrollable */}
                        <table className="min-w-full border-collapse border-2">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="border px-4 py-2 text-left">Name</th>
                                    <th className="border px-4 py-2 text-left">API Key</th>
                                    <th className="border px-4 py-2 text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {apiKeys.length === 0 ? (
                                    <tr>
                                        <td className="border px-4 py-2 text-white" colSpan="3">No API keys found.</td>
                                    </tr>
                                ) : (
                                    apiKeys.map((apiKey, index) => (
                                        <tr key={index}>
                                            <td className="border px-4 py-2 text-yellow-200">{apiKey.name}</td>
                                            <td className="border px-4 py-2 text-yellow-400">
                                                {apiKey.apiKeyHash ? (apiKey.apiKeyHash.slice(0, 5) + '*****' + apiKey.apiKeyHash.slice(-3)) : 'N/A'}
                                            </td>
                                            <td className="border px-4 py-2">
                                                <button
                                                    className="text-red-500 hover:text-red-700 transition duration-200"
                                                    onClick={() => {
                                                        setApiKeyToDelete(apiKey.apiKeyId);
                                                        setShowConfirmModal(true);
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Charts Section */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Bar Chart */}
                    <div className="p-4 sm:p-6 bg-gray-50 rounded-lg shadow">
                        <h2 className="text-xl font-semibold mb-4">Bot Usage (Bar Chart)</h2>
                        <Bar data={chartData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
                    </div>

                    {/* Line Chart */}
                    <div className="p-4 sm:p-6 bg-gray-50 rounded-lg shadow">
                        <h2 className="text-xl font-semibold mb-4">Bot Usage (Line Chart)</h2>
                        <Line data={{ labels: chartData.labels, datasets: chartData.datasets }} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
                    </div>
                </div>

                {/* Modal Components */}
                {/* API Key Name Modal */}
                {showNameModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
                            <h2 className="text-xl font-semibold mb-4">Enter API Key Name</h2>
                            <input
                                type="text"
                                className="border rounded-lg p-2 mb-4 w-full"
                                placeholder="API Key Name"
                                value={apiKeyName}
                                onChange={(e) => setApiKeyName(e.target.value)}
                            />
                            <div className="flex justify-end space-x-2">
                                <button
                                    className="bg-gray-400 text-white py-1 px-4 rounded-lg"
                                    onClick={() => setShowNameModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-blue-600 text-white py-1 px-4 rounded-lg"
                                    onClick={generateApiKey}
                                >
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* API Key Display Modal */}
                {showKeyModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
                            <h2 className="text-xl font-semibold mb-4">Your Generated API Key</h2>
                            <input
                                type="text"
                                value={generatedApiKey}
                                readOnly
                                className="border rounded-lg w-full py-2 px-3 mb-4"
                            />
                            <button
                                className="bg-blue-600 text-white p-2 rounded-lg shadow hover:bg-blue-700 transition duration-200 w-full"
                                onClick={copyToClipboard}
                            >
                                Copy API Key
                            </button>
                            <div className="flex justify-end space-x-2 mt-4">
                                <button
                                    className="bg-gray-400 text-white py-1 px-4 rounded-lg"
                                    onClick={() => {
                                        setShowKeyModal(false);
                                        setApiKeyName(null);
                                        setGeneratedApiKey(null);
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                            <p className="mt-2 text-sm text-red-600">
                                ⚠️ Copy and secure your API key. It cannot be retrieved later!
                            </p>
                        </div>
                    </div>
                )}

                <Modal isOpen={isPaymentModalOpen} onClose={() => setIsPaymentModalOpen(false)}>
                    {orderDetails && (
                        <MarxIPGOrder
                            price={orderDetails.price}
                            summary={orderDetails.summary}
                            type={orderDetails.type}
                        />
                    )}
                </Modal>

                {/* Confirmation Modal for Deleting API Key */}
                {showConfirmModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
                            <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
                            <p className="mb-4">Are you sure you want to delete this API key? This action cannot be undone.</p>
                            <div className="flex justify-end space-x-2">
                                <button
                                    className="bg-gray-400 text-white py-1 px-4 rounded-lg"
                                    onClick={() => setShowConfirmModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-red-600 text-white py-1 px-4 rounded-lg"
                                    onClick={deleteApiKey}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>

                )}

                <div className='mb-4'></div>
                <Footer />
            </div>
        </>
    );
};

export default UsagePage;
