import React, { useState } from 'react';

const AdCampaignPage = () => {
    const [step, setStep] = useState(1);
    const [campaignDetails, setCampaignDetails] = useState({
        name: '',
        platforms: [],
        audience: '',
        goal: 'Awareness',
        description: '',
        tone: 'Professional',
        budget: '',
        startDate: '',
        endDate: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "platforms") {
            // Handle multi-platform checkbox selection
            setCampaignDetails((prevDetails) => {
                const updatedPlatforms = e.target.checked
                    ? [...prevDetails.platforms, value]
                    : prevDetails.platforms.filter((platform) => platform !== value);
                return { ...prevDetails, platforms: updatedPlatforms };
            });
        } else {
            setCampaignDetails({ ...campaignDetails, [name]: value });
        }
    };

    const handleNext = () => setStep((prev) => Math.min(prev + 1, 4));
    const handleBack = () => setStep((prev) => Math.max(prev - 1, 1));

    const handleSubmit = () => console.log("create");

    return (
        <div className="min-h-screen bg-gray-900/70 mt-10 pt-1">
            <div className='mt-20'></div>
            <header className="bg-white shadow">
                <div className="max-w-7xl mx-auto py-4 px-6 flex justify-between items-center">
                    <h1 className="text-2xl font-bold text-purple-700 uppercase">Ad Campaign Optimizer</h1>
                    <button className="px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold rounded-lg shadow-md transition-all duration-300 ease-in-out 'hover:from-pink-600 hover:to-purple-600 hover:scale-105'">Upgrade Plan</button>
                </div>
            </header>

            <main className="max-w-5xl mx-auto py-10 px-6">
                {/* Progress Indicator */}
                <div className="flex justify-between items-center mb-8">
                    {[1, 2, 3, 4].map((num) => (
                        <div key={num} className={`flex-1 text-center ${step >= num ? 'text-pink-600' : 'text-gray-50'}`}>
                            <div className={`w-8 h-8 mx-auto rounded-full border ${step >= num ? 'bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold shadow-md transition-all duration-300 ease-in-out border-green-60' : 'border-gray-400'}`}>
                                {num}
                            </div>
                            <p className="mt-2 text-sm">Step {num}</p>
                        </div>
                    ))}
                </div>

                {/* Step Forms */}
                {step === 1 && (
                    <div>
                        <h2 className="text-xl font-bold mb-4 text-white">Step 1: Campaign Details</h2>
                        <div className="grid grid-cols-1 gap-6">
                            <input
                                type="text"
                                name="name"
                                value={campaignDetails.name}
                                onChange={handleInputChange}
                                placeholder="Campaign Name"
                                className="p-3 border border-gray-300 rounded focus:ring-green-500"
                            />
                            <div className="space-y-2">
                                <p className="text-white text-xl">Select Platforms</p>
                                <div className="flex space-x-6">
                                    <label className="inline-flex items-center text-white text-lg">
                                        <input
                                            type="checkbox"
                                            name="platforms"
                                            value="Facebook"
                                            checked={campaignDetails.platforms.includes('Facebook')}
                                            onChange={handleInputChange}
                                            className="form-checkbox w-6 h-6"
                                        />
                                        <span className="ml-3">Facebook</span>
                                    </label>
                                    <label className="inline-flex items-center text-white text-lg">
                                        <input
                                            type="checkbox"
                                            name="platforms"
                                            value="Google"
                                            checked={campaignDetails.platforms.includes('Google')}
                                            onChange={handleInputChange}
                                            className="form-checkbox w-6 h-6"
                                        />
                                        <span className="ml-3">Google</span>
                                    </label>
                                    <label className="inline-flex items-center text-white text-lg">
                                        <input
                                            type="checkbox"
                                            name="platforms"
                                            value="Instagram"
                                            checked={campaignDetails.platforms.includes('Instagram')}
                                            onChange={handleInputChange}
                                            className="form-checkbox w-6 h-6"
                                        />
                                        <span className="ml-3">Instagram</span>
                                    </label>
                                    <label className="inline-flex items-center text-white text-lg">
                                        <input
                                            type="checkbox"
                                            name="platforms"
                                            value="LinkedIn"
                                            checked={campaignDetails.platforms.includes('LinkedIn')}
                                            onChange={handleInputChange}
                                            className="form-checkbox w-6 h-6"
                                        />
                                        <span className="ml-3">LinkedIn</span>
                                    </label>
                                </div>
                            </div>

                            <textarea
                                name="audience"
                                value={campaignDetails.audience}
                                onChange={handleInputChange}
                                placeholder="Target Audience (e.g., demographics, interests)"
                                className="p-3 border border-gray-300 rounded focus:ring-green-500"
                            />
                            <select
                                name="goal"
                                value={campaignDetails.goal}
                                onChange={handleInputChange}
                                className="p-3 border border-gray-300 rounded focus:ring-green-500"
                            >
                                <option value="Awareness">Awareness</option>
                                <option value="Engagement">Engagement</option>
                                <option value="Conversions">Conversions</option>
                            </select>
                        </div>
                    </div>
                )}

                {step === 2 && (
                    <div>
                        <h2 className="text-xl font-bold mb-4 text-white">Step 2: Ad Copy Generation</h2>
                        <textarea
                            name="description"
                            value={campaignDetails.description}
                            onChange={handleInputChange}
                            placeholder="Describe your product or service"
                            className="w-full h-80 p-3 border border-gray-300 rounded focus:ring-green-500"
                        />

                        <select
                            name="tone"
                            value={campaignDetails.tone}
                            onChange={handleInputChange}
                            className="mt-4 p-3 border border-gray-300 rounded focus:ring-green-500"
                        >
                            <option value="Professional">Professional</option>
                            <option value="Casual">Casual</option>
                            <option value="Urgent">Urgent</option>
                            <option value="Fun">Fun</option>
                        </select>
                    </div>
                )}

                {step === 3 && (
                    <div>
                        <h2 className="text-xl font-bold mb-4 text-white">Step 3: Budget & Scheduling</h2>
                        <input
                            type="number"
                            name="budget"
                            value={campaignDetails.budget}
                            onChange={handleInputChange}
                            placeholder="Budget (e.g., $500)"
                            className="p-3 border border-gray-300 rounded focus:ring-green-500"
                        />
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <input
                                type="date"
                                name="startDate"
                                value={campaignDetails.startDate}
                                onChange={handleInputChange}
                                className="p-3 border border-gray-300 rounded focus:ring-green-500"
                            />
                            <input
                                type="date"
                                name="endDate"
                                value={campaignDetails.endDate}
                                onChange={handleInputChange}
                                className="p-3 border border-gray-300 rounded focus:ring-green-500"
                            />
                        </div>
                    </div>
                )}

                {step === 4 && (
                    <div>
                        <h2 className="text-xl font-bold mb-4 text-white">Step 4: Review Campaign</h2>
                        <div className="space-y-4">
                            <p className="text-white"><strong>Campaign Name:</strong> {campaignDetails.name}</p>
                            <p className="text-white"><strong>Selected Platforms:</strong> {campaignDetails.platforms.join(', ')}</p>
                            <p className="text-white"><strong>Target Audience:</strong> {campaignDetails.audience}</p>
                            <p className="text-white"><strong>Campaign Goal:</strong> {campaignDetails.goal}</p>
                            <p className="text-white"><strong>Description:</strong> {campaignDetails.description}</p>
                            <p className="text-white"><strong>Tone:</strong> {campaignDetails.tone}</p>
                            <p className="text-white"><strong>Budget:</strong> ${campaignDetails.budget}</p>
                            <p className="text-white"><strong>Start Date:</strong> {campaignDetails.startDate}</p>
                            <p className="text-white"><strong>End Date:</strong> {campaignDetails.endDate}</p>
                        </div>
                    </div>
                )}

                {/* Navigation Buttons */}
                <div className="mt-8 flex justify-between">
                    <button
                        onClick={handleBack}
                        className={`px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 ${step === 1 ? 'cursor-not-allowed opacity-50' : ''}`}
                        disabled={step === 1}
                    >
                        Back
                    </button>
                    <button
                        onClick={step !== 4 ? handleNext : handleSubmit}
                        className="px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold rounded-lg shadow-md transition-all duration-300 ease-in-out 'hover:from-pink-600 hover:to-purple-600 hover:scale-105'"
                    >
                        {step === 4 ? 'Create Campaign' : 'Next'}
                    </button>
                </div>
            </main>
        </div>
    );
};

export default AdCampaignPage;
