import React from 'react';
import { Link } from 'react-router-dom';
import { FaRocket, FaClipboardList, FaBullhorn, FaEnvelope, FaSearch } from 'react-icons/fa'; // Icons for visual appeal
import { Helmet } from 'react-helmet-async';

const services = [
    {
        id: 'AI Website Performance & SEO Booster',
        title: 'AI Website Performance & SEO Booster',
        description: 'Analyze and optimize your website for better performance and SEO.',
        icon: <FaSearch className="text-5xl text-blue-400" />
    },
    {
        id: 'AI-Driven Social Media Strategy Suite',
        title: 'AI-Driven Social Media Strategy Suite',
        description: 'Plan, schedule, and optimize social media content effortlessly.',
        icon: <FaBullhorn className="text-5xl text-yellow-400" />
    },
    {
        id: 'AI Content Creation & Enhancement Suite',
        title: 'AI Content Creation & Enhancement Suite',
        description: 'Generate high-quality blogs, posts, and email marketing content.',
        icon: <FaClipboardList className="text-5xl text-green-400" />
    },
    {
        id: 'AI Ad Campaign Optimizer',
        title: 'AI Ad Campaign Optimizer',
        description: 'Create impactful ad campaigns with AI-driven insights.',
        icon: <FaRocket className="text-5xl text-purple-400" />
    },
    {
        id: 'AI Email Marketing Automation Suite',
        title: 'AI Email Marketing Automation Suite',
        description: 'Automate email campaigns with personalized templates and predictions.',
        icon: <FaEnvelope className="text-5xl text-pink-400" />
    },
];

function BusinessServiceSelection() {
    return (
        <div>
            <Helmet>
                <title>Select Your Business Service | Botanion</title>
                <meta name="description" content="Choose the best business service to enhance your operations." />
                <meta name="keywords" content="Botanion, business services, performance audit, SEO, content creation, marketing" />

                {/* Open Graph tags */}
                <meta property="og:title" content="Select Your Business Service | Botanion" />
                <meta property="og:description" content="Choose the best business service to enhance your operations." />
                <meta property="og:image" content="https://res.cloudinary.com/dbe2fast6/image/upload/v1728982601/business-service-image.jpg" /> {/* Provide a valid URL to an image */}
                <meta property="og:url" content="https://www.botanion.cc/business-service-selection" />
                <meta property="og:type" content="website" />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Select Your Business Service | Botanion" />
                <meta name="twitter:description" content="Choose the best business service to enhance your operations." />
                <meta name="twitter:image" content="https://res.cloudinary.com/dbe2fast6/image/upload/v1728982601/business-service-image.jpg" /> {/* Provide a valid URL to an image */}
            </Helmet>

            <div className="container mx-auto px-6 py-12 text-center">
                <h1
                    className="text-4xl uppercase font-extrabold text-white mb-6 mt-6"
                    style={{ textShadow: '4px 4px 8px rgba(0, 0, 0, 0.4)' }}
                >
                    Select Your Business Service
                </h1>
                <p className="text-lg text-gray-300 mb-12">
                    Choose the service that best fits your business goals and enhances your operations.
                </p>

                <div className="flex flex-wrap justify-center gap-8">
                    {services.map((service) => (
                        <div key={service.id} className="bg-white/10 border-2 border-gray-100 backdrop-blur-md p-8 rounded-2xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl w-80">
                            <div className="flex justify-center mb-6">
                                {service.icon}
                            </div>
                            <h2 className="text-2xl font-bold text-white mb-4">{service.title}</h2>
                            <p className="text-gray-300 mb-6">
                                {service.description}
                            </p>
                            <Link to={`/create-business-service/${service.id}`}>
                                <button className="w-full py-3 bg-blue-500 hover:bg-blue-400 text-white rounded-lg font-semibold transition-colors">
                                    Select
                                </button>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default BusinessServiceSelection;
