import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { setSecureCookie } from '../utilities/safeSaves';

const CompleteProfilePage = () => {
    const location = useLocation();
    const { email, name, userId, emailVerified } = location.state || {};
    const [password, setPassword] = useState('');
    const [repassword, setRePassword] = useState('');
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isFetchingCountries, setIsFetchingCountries] = useState(true);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [marketingUpdates, setMarketingUpdates] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email && !name && !userId && !emailVerified) {
            window.location.replace('/login');
            return;
        }

        const fetchCountries = async () => {
            setIsFetchingCountries(true);
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const sortedCountries = response.data.sort((a, b) => a.name.common.localeCompare(b.name.common));
                setCountries(sortedCountries);
            } catch (primaryError) {
                console.error('Primary API failed:', primaryError.message);
                try {
                    const fallbackResponse = await axios.get('https://raw.githubusercontent.com/samayo/country-json/master/src/country-by-name.json');
                    const sortedFallbackCountries = fallbackResponse.data.sort((a, b) => a.country.localeCompare(b.country));
                    setCountries(sortedFallbackCountries.map((c) => ({ name: { common: c.country } })));
                } catch (fallbackError) {
                    console.error('Fallback API failed:', fallbackError.message);
                    setError('Failed to load the list of countries. Please try again later.');
                }
            } finally {
                setIsFetchingCountries(false);
            }
        };

        fetchCountries();
    }, [email, name, userId, emailVerified]);

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+\[\]{};:'",.<>?/]).{12,}$/;
        if (!regex.test(password)) {
            return 'Password must be at least 12 characters long, including uppercase, lowercase, numbers, and special characters.';
        }
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const passwordError = validatePassword(password);
        if (passwordError) {
            setError(passwordError);
            return;
        }

        if (password !== repassword) {
            setError('Passwords do not match.');
            return;
        }

        if (!country) {
            setError('Country is required.');
            return;
        }

        if (!acceptTerms) {
            setError('You must accept the Privacy Policy and Terms & Conditions.');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post('https://api-bny5h3g2lq-uc.a.run.app/api/google-signup', {
                name,
                email,
                password,
                country,
                uid: userId,
                emailVerified,
                marketingUpdates,
            });

            const { token, uid, plan } = response.data;

            setSecureCookie('accessToken', token, { expires: 7, secure: true });
            setSecureCookie('uid', uid, { expires: 7, secure: true });
            setSecureCookie('username', name, { expires: 7, secure: true });
            setSecureCookie('plan', plan, { expires: 7, secure: true });

            window.location.replace('/');
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to complete profile. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center">
            <div className="bg-white/10 shadow-xl rounded-3xl overflow-hidden h-3/4 w-11/12 m-5">
                <div className="p-10 flex flex-col justify-center">
                    <h2 className="text-4xl font-bold text-center text-purple-300 mb-2">Complete Your Profile</h2>
                    <p className="text-center text-gray-200 mb-4">Provide additional details to finish setting up your account.</p>
                    {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">{error}</div>}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-200 font-medium">Password</label>
                            <input
                                type="password"
                                className="w-full p-3 border border-gray-300 rounded-2xl bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-600"
                                placeholder="Enter a strong password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-200 font-medium">Confirm Password</label>
                            <input
                                type="password"
                                className="w-full p-3 border border-gray-300 rounded-2xl bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-600"
                                placeholder="Confirm your password"
                                value={repassword}
                                onChange={(e) => setRePassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-200 font-medium">Country</label>
                            {isFetchingCountries ? (
                                <p className="text-gray-400">Loading countries...</p>
                            ) : (
                                <select
                                    className="w-full p-3 mt-2 border rounded-2xl bg-white/5 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>Select your country</option>
                                    {countries.map((country) => (
                                        <option
                                            key={country.name.common}
                                            value={country.name.common}
                                            className="bg-black text-white"
                                        >
                                            {country.name.common}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                        <div className="mb-4">
                            <label className="text-white">
                                <input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={acceptTerms}
                                    onChange={(e) => setAcceptTerms(e.target.checked)}
                                />
                                I accept the <a href="/privacy-policy" className="text-purple-400 underline">Privacy Policy</a> and <a href="/terms" className="text-purple-400 underline">Terms & Conditions</a>.
                            </label>
                        </div>
                        <div className="mb-4">
                            <label className="text-white">
                                <input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={marketingUpdates}
                                    onChange={(e) => setMarketingUpdates(e.target.checked)}
                                />
                                I want to receive updates and marketing emails.
                            </label>
                        </div>
                        <button
                            type="submit"
                            className={`w-full bg-purple-600 text-white py-3 rounded-lg font-semibold transition duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700'}`}
                            disabled={loading}
                        >
                            {loading ? 'Processing...' : 'Complete Profile'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CompleteProfilePage;
