import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSecureCookie } from '../utilities/safeSaves';

const BusinessConsole = () => {
    const navigate = useNavigate();

    const [businesses, setBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBusinesses = async () => {
            try {
                const response = await fetch('http://localhost:5000/get-businesses', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${getSecureCookie('accessToken')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch businesses');
                }

                const data = await response.json();
                setBusinesses(data.businesses || []);
            } catch (err) {
                console.error('Error fetching businesses:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinesses();
    }, []);

    const handleCreateBusiness = () => {
        navigate('/AI-business/createbusiness');
    };

    const formatDate = (timestamp) => {
        // Check if timestamp is a Firestore Timestamp object
        if (timestamp && timestamp._seconds) {
            const date = new Date(timestamp._seconds * 1000); // Convert seconds to milliseconds
            return date.toLocaleDateString(); // Format to readable string
        }
        return 'Unknown Date'; // Fallback for invalid or missing dates
    };

    return (
        <div className="min-h-screen text-white p-6">
            {/* Page Header */}
            <header className="flex justify-between items-center mb-8 mt-16">
                <h1
                    className="text-2xl sm:text-3xl font-bold uppercase"
                    style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}
                >
                    AI Business Console
                </h1>
                <button
                    onClick={handleCreateBusiness}
                    className="py-2 px-4 bg-gradient-to-r from-purple-600 to-pink-600 text-white rounded-lg shadow-lg font-semibold hover:scale-105 transition-transform"
                >
                    + Create Business
                </button>
            </header>

            {/* Loading State */}
            {loading && (
                <div className="text-center mt-12">
                    <p className="text-gray-400">Loading businesses...</p>
                </div>
            )}

            {/* Error State */}
            {error && (
                <div className="text-center mt-12">
                    <p className="text-red-400">Error: {error}</p>
                </div>
            )}

            {/* Business List */}
            {!loading && businesses.length > 0 && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {businesses.map((business) => (
                        <div
                            key={business.id}
                            className="bg-gray-50/20 rounded-lg shadow-lg p-6 flex flex-col justify-between hover:shadow-xl transition-shadow h-60"
                        >
                            <div>
                                <h2
                                    className="font-semibold mb-2 text-gray-950 text-2xl"
                                    style={{ textShadow: '1px 1px 2px rgba(255, 255, 255, 0.8)' }}
                                >
                                    {business.businessName}
                                </h2>
                                <p className="text-gray-400">
                                    Created on: {formatDate(business.createdAt)}
                                </p>
                            </div>
                            <button
                                onClick={() => navigate(`/AI-business/${business.id}/services`)}
                                className="mt-4 py-2 px-4 bg-purple-600 text-white rounded-lg shadow hover:bg-purple-700"
                            >
                                Manage
                            </button>
                        </div>
                    ))}
                </div>
            )}

            {/* No Businesses Placeholder */}
            {!loading && businesses.length === 0 && (
                <div className="text-center mt-12">
                    <h3 className="text-lg sm:text-xl text-gray-400">
                        You have no businesses yet. Create one to get started.
                    </h3>
                </div>
            )}
        </div>
    );
};

export default BusinessConsole;
