import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getSecureCookie } from '../utilities/safeSaves';
import pako from 'pako';
import { Buffer } from 'buffer';
import LoadingScreen from './LoadingScreen';


function CreateBusiness() {
    const [formData, setFormData] = useState({
        businessName: '',
        businessUrl: '',
        description: '',
        contactEmail: '',
        contactNumber: '',
        address: '',
    });

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [gettingDataLoading, setGettingDataLoading] = useState(false);
    const [CreatingBusinessLoading, setCreatingBusinessLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        const { businessName, businessUrl, description, contactEmail, contactNumber, address } = formData;

        // Frontend validation for required fields
        if (!businessName || !businessUrl || !description) {
            setErrorMessage('Business Name, URL, and Description are required.');
            return;
        }

        // Normalize and validate the business URL
        let trimmedBusinessUrl = businessUrl.trim();
        if (!/^https?:\/\//i.test(trimmedBusinessUrl)) {
            trimmedBusinessUrl = 'https://' + trimmedBusinessUrl;
        }

        setLoading(true); // Start loading indicator
        setErrorMessage(''); // Clear previous error messages

        try {
            const accessToken = getSecureCookie('accessToken'); // Get access token securely
            setLoading(false); // Stop loading indicator
            setGettingDataLoading(true); // Stop loading indicator

            // Step 1: Scrape Business Data
            const { data: rawScrapedData } = await axios.post(
                'https://api-283871041518.us-central1.run.app/business-scrape',
                { url: trimmedBusinessUrl },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            // Step 2: Compress and encode the scraped data
            let encodedScrapedData;
            try {
                const compressedData = pako.deflate(JSON.stringify(rawScrapedData), { to: 'string' });
                encodedScrapedData = Buffer.from(compressedData).toString('base64');
                setGettingDataLoading(false); // Stop loading indicator
                setCreatingBusinessLoading(true); // Stop loading indicator
            } catch (compressionError) {
                console.error('Error compressing scraped data:', compressionError);
                setErrorMessage('Failed to process scraped data.');
                return;
            }

            // Step 3: Submit the data to create the business
            await axios.post(
                'http://localhost:5000/create-business',
                {
                    businessName,
                    businessUrl: trimmedBusinessUrl,
                    description,
                    contactEmail: contactEmail || '', // Optional fields default to empty
                    contactNumber: contactNumber || '',
                    address: address || '',
                    scrapedData: encodedScrapedData,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            // Success: Navigate to the business list page
            alert('Business successfully created!');
            navigate('/business-list');
        } catch (error) {
            // Log the error and set appropriate error messages
            console.error('Error:', error);

            if (error.response) {
                // API returned an error response
                if (error.response.status === 400) {
                    setErrorMessage('Invalid input. Please check the details and try again.');
                } else if (error.response.status === 500) {
                    setErrorMessage('Server error. Please try again later.');
                } else {
                    setErrorMessage('Unexpected error occurred. Please try again.');
                }
            } else {
                // Network or other unexpected errors
                setErrorMessage('Failed to create business. Please check your connection and try again.');
            }
        } finally {
            setCreatingBusinessLoading(false); // Stop loading indicator
        }
    };

    return (
        <>
            {gettingDataLoading ? (<LoadingScreen textToShow={`Getting your business data from website... ${formData.businessUrl}`} />) : CreatingBusinessLoading ? (<LoadingScreen textToShow={"Creating your business..."} />) : (
                <>
                    <div className="flex justify-center items-center mb-6 mt-10 sm:mt-16">
                        <h1
                            className="text-2xl sm:text-3xl uppercase text-white font-bold text-center mt-10"
                            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}
                        >
                            Create Business
                        </h1>
                    </div>


                    <div className="mb-4 sm:flex sm:items-center max-w-3xl md:max-w-5xl mx-auto"> {/* Added sm:items-center for vertical alignment */}
                        <img
                            src="https://res.cloudinary.com/dbe2fast6/image/upload/v1732848439/freepik__candid-image-photography-natural-textures-highly-r__18474_axr23m.jpg"
                            alt="Chatbot create business"
                            className="hidden sm:block w-full h-64 object-cover rounded-lg shadow-md" // Hide on mobile, show on sm and larger
                        />
                    </div>


                    <div className="flex flex-col sm:flex-row max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto bg-black/10 rounded-lg shadow-lg overflow-hidden">
                        {/* Left Side Form */}
                        <div className="w-full sm:w-1/2 p-4 sm:p-8 overflow-y-auto">
                            <div className="mb-4 sm:hidden">
                                <img src="https://res.cloudinary.com/dbe2fast6/image/upload/v1732848439/freepik__candid-image-photography-natural-textures-highly-r__18474_axr23m.jpg" alt="Create Chatbot Business" className="w-full h-32 object-cover rounded-lg shadow-md" />
                            </div>

                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                <div className="mb-4">
                                    <label
                                        htmlFor="businessName"
                                        className="block text-base sm:text-lg font-medium text-white mb-2"
                                    >
                                        Business Name
                                    </label>
                                    <input
                                        type="text"
                                        id="businessName"
                                        name="businessName"
                                        value={formData.businessName}
                                        onChange={handleChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                        required
                                    />
                                </div>

                                <div className="mb-4">
                                    <label
                                        htmlFor="businessUrl"
                                        className="block text-base sm:text-lg font-medium text-white mb-2"
                                    >
                                        Business URL
                                    </label>
                                    <input
                                        type="url"
                                        id="businessUrl"
                                        name="businessUrl"
                                        value={formData.businessUrl}
                                        onChange={handleChange}
                                        placeholder="https://example.com"
                                        className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                        required
                                    />
                                </div>

                                <div className="mb-4">
                                    <label
                                        htmlFor="description"
                                        className="block text-base sm:text-lg font-medium text-white mb-2"
                                    >
                                        Description
                                    </label>
                                    <textarea
                                        id="description"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                        required
                                        rows="4"
                                    />
                                </div>
                            </form>
                        </div>

                        {/* Right Side Form */}
                        <div className="w-full sm:w-1/2 p-4 sm:p-8 overflow-y-auto">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                {["address", "contactEmail", "contact Number"].map((field, index) => (
                                    <div className="mb-4" key={index}>
                                        <label
                                            htmlFor={field}
                                            className="block text-base sm:text-lg font-medium text-white mb-2"
                                        >
                                            {field === "contactEmail"
                                                ? "Contact Email"
                                                : field.charAt(0).toUpperCase() + field.slice(1)}{' '}
                                            (optional)
                                        </label>
                                        <input
                                            type={field === "contactEmail" ? "email" : field === "contactNumber" ? "tel" : "text"}
                                            id={field}
                                            name={field}
                                            value={formData[field]}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                        />
                                    </div>
                                ))}

                                <button
                                    type="submit"
                                    className={`w-full py-3 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold rounded-lg shadow-md transition-all duration-300 ease-in-out 
                        ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:from-pink-600 hover:to-purple-600 hover:scale-105'}`}
                                >
                                    {loading ? 'Creating Business...' : 'Create Business'}
                                </button>

                                {errorMessage && (
                                    <div className="mt-4 sm:mt-6 bg-red-100 p-4 rounded-lg shadow-lg">
                                        <h3 className="text-lg sm:text-xl font-bold text-red-700">Error:</h3>
                                        <p className="text-red-600">{errorMessage}</p>
                                    </div>
                                )}
                            </form>

                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default CreateBusiness;
