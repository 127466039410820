import React, { useState } from "react";
import { getSecureCookie } from "../utilities/safeSaves";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        messagesPerMonth: "",
        bots: "",
        industry: "",
        additionalInfo: "",
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" })); // Clear errors on change
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) {
            newErrors.name = "Name is required.";
        }
        if (!formData.email.trim()) {
            newErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email address.";
        }
        if (!formData.messagesPerMonth || formData.messagesPerMonth <= 0) {
            newErrors.messagesPerMonth = "Messages per month must be greater than 0.";
        }
        if (!formData.bots || formData.bots <= 0) {
            newErrors.bots = "Number of bots must be greater than 0.";
        }
        if (!formData.industry.trim()) {
            newErrors.industry = "Industry is required.";
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsSubmitting(true);

        const uid = getSecureCookie("uid");
        const message = `
            Name: ${formData.name}\n
            Email: ${formData.email}\n
            Messages Per Month: ${formData.messagesPerMonth}\n
            Number of Bots: ${formData.bots}\n
            Industry: ${formData.industry}\n
            Additional Info: ${formData.additionalInfo || "N/A"}
        `;

        try {
            const response = await fetch("https://api-bny5h3g2lq-uc.a.run.app/api/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ uid, message }),
            });

            if (!response.ok) {
                throw new Error("Failed to send data. Please try again later.");
            }

            alert("Your message has been sent successfully!");
            setFormData({
                name: "",
                email: "",
                messagesPerMonth: "",
                bots: "",
                industry: "",
                additionalInfo: "",
            });
        } catch (error) {
            console.error(error);
            alert("Error: Unable to submit the form. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center px-4">
            <div className="max-w-xl w-full bg-white rounded-lg shadow-lg p-6 mt-16">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Contact Us</h2>
                <p className="text-gray-600 mb-6">
                    Let us know your requirements, and we'll provide you with a tailored quotation.
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700 font-medium mb-2">
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Enter your name"
                        />
                        {errors.name && (
                            <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Enter your email"
                        />
                        {errors.email && (
                            <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="messagesPerMonth"
                            className="block text-gray-700 font-medium mb-2"
                        >
                            Messages Per Month
                        </label>
                        <input
                            type="number"
                            id="messagesPerMonth"
                            name="messagesPerMonth"
                            value={formData.messagesPerMonth}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Enter number of messages"
                        />
                        {errors.messagesPerMonth && (
                            <p className="text-red-500 text-sm mt-1">{errors.messagesPerMonth}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="bots" className="block text-gray-700 font-medium mb-2">
                            Number of Bots
                        </label>
                        <input
                            type="number"
                            id="bots"
                            name="bots"
                            value={formData.bots}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Enter number of bots"
                        />
                        {errors.bots && (
                            <p className="text-red-500 text-sm mt-1">{errors.bots}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="industry" className="block text-gray-700 font-medium mb-2">
                            Industry
                        </label>
                        <input
                            type="text"
                            id="industry"
                            name="industry"
                            value={formData.industry}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Enter your industry"
                        />
                        {errors.industry && (
                            <p className="text-red-500 text-sm mt-1">{errors.industry}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="additionalInfo"
                            className="block text-gray-700 font-medium mb-2"
                        >
                            Additional Information
                        </label>
                        <textarea
                            id="additionalInfo"
                            name="additionalInfo"
                            value={formData.additionalInfo}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="Tell us more about your needs"
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className={`w-full py-3 text-white rounded-lg font-semibold transition-colors ${isSubmitting ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-400"
                            }`}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactUs;
